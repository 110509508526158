<template>
  <section class="mb-12">
    <h1 class="text-5xl leading-tight mb-6">
      Send your own <br />
      Augmented Reality <br />
      Christmas Cards!
    </h1>
    <div class="flex mx-0 items-baseline" v-for="step in steps" :key="step.id">
      <div class="step-left">STEP {{ step.id }}.</div>
      <div class="step-right">
        {{ step.text }}
      </div>
    </div>
  </section>
</template>

<script>
import { reactive } from 'vue'

export default {
  name: 'Intro',
  setup() {
    const steps = reactive([
      {
        id: 1,
        text: 'Choose your AR Card.'
      },
      {
        id: 2,
        text: 'Leave your Message.'
      },
      {
        id: 3,
        text: 'Create your AR Card Link.'
      },
      {
        id: 4,
        text: 'Send your own AR Card Link to your friends or family.'
      }
    ])

    return {
      steps
    }
  }
}
</script>

<style lang="scss" scoped>
.step-left {
  @apply w-3/12 text-3xl leading-normal px-0;
}

.step-right {
  @apply w-9/12 text-2xl leading-normal px-0;
}
</style>
