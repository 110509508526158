<template>
  <div class="flex items-baseline justify-between mb-2">
    <h1 class="text-4xl">
      <slot name="title">Default Title</slot>
    </h1>
    <div class="text-gray-400 text-2xl">
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar'
}
</script>
