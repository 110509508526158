import axios from 'axios'

class CardAPI {
  static #baseURL = '/'

  static setBaseURL(HOST_NAME) {
    this.#baseURL = `https://${HOST_NAME}/api/v1/xmax-card-content`
  }

  /**
   * @param {object} data
   * @param {number} data.card_id
   * @param {string} data.card_content
   */
  static postCard(data) {
    return axios.post(`${this.#baseURL}`, data)
  }
}

export default CardAPI
