<template>
  <Spinner v-show="isLoading"></Spinner>
  <div v-show="!isLoading">
    <header>
      <nav class="flex py-5 px-7">
        <a href="https://www.spe3d.co/web-ar" class="w-36 inline-block">
          <img :src="`${IMG_ROOT}/webAR_logo.png`" class="w-full" />
        </a>
      </nav>
      <div>
        <img :src="`${IMG_ROOT}/index/webAR_kv_banner.jpg`" class="w-full" />
      </div>
    </header>
    <main class="py-6 px-11">
      <Intro />
      <TemplateCard @pickCard="pickCard" ref="templateCard" />
      <WishInput
        :pickedId="cardData.card_id"
        @onChange="onChange"
        ref="wishInput"
      />
      <div class="text-center">
        <button
          class="btn-green btn-submit disabled:opacity-60"
          @click="beforeSubmit"
          :disabled="isProcessing"
        >
          Create your AR Card Link
        </button>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import { reactive, ref, provide, getCurrentInstance } from 'vue'
import Intro from '@/components/Intro.vue'
import TemplateCard from '@/components/TemplateCard.vue'
import WishInput from '@/components/WishInput.vue'
import Footer from '@/components/Footer.vue'
import Spinner from '@/components/Spinner.vue'
import indexData from '@/assets/index_data.json'
import CardAPI from '@/apis/cardAPI.js'

export default {
  name: 'Index',
  components: {
    Intro,
    TemplateCard,
    WishInput,
    Footer,
    Spinner
  },
  setup() {
    const IMG_ROOT = getCurrentInstance().proxy.IMG_ROOT
    const readyState = document.readyState

    // Set Data
    const pageData = indexData
    provide('pageData', pageData)

    const cardData = reactive({
      card_id: 1,
      content: ''
    })
    const isProcessing = ref(false)
    const isLoading = ref(true)
    const wishInput = ref()

    // Pre load images if first entry
    if (readyState !== 'complete') {
      preLoadImg()
      window.addEventListener('load', switchState)
    } else {
      isLoading.value = false
    }

    // Helper function
    function preLoadImg() {
      const cards = indexData.cards
      cards.forEach(card => {
        const img = new Image()
        img.src = `${IMG_ROOT}/index/${card.bg}`
      })
    }

    function switchState() {
      isLoading.value = false
      window.removeEventListener('load', switchState)
    }

    return {
      cardData,
      isProcessing,
      isLoading,
      wishInput
    }
  },
  methods: {
    onChange(newValue) {
      this.cardData.content = newValue
    },
    pickCard(newVal) {
      this.cardData.card_id = newVal
      this.$scrollTo('#wishHead')
    },
    beforeSubmit() {
      try {
        // Check null
        const msgEnum = {
          card_id: 'Please select a Christmas Card.',
          content: 'Please enter your wishes.'
        }
        Object.entries(this.cardData).forEach(entry => {
          const [key, value] = entry
          if (!value) throw { key, msg: msgEnum[key] }
        })

        this.submit()
      } catch (e) {
        const { key, msg } = e

        this.$swal.fire({
          icon: 'error',
          text: msg,
          didClose: () => {
            if (key === 'content') {
              const textarea = this.wishInput.$refs.textarea
              textarea.focus()
            }
          }
        })
      }
    },
    async submit() {
      this.isProcessing = true
      const payload = {
        card_id: this.cardData.card_id,
        card_content: this.cardData.content
      }

      try {
        const { data } = await CardAPI.postCard(payload)
        const card_id = data?.message?.card_id
        if (!card_id) throw new Error("Can't access card id")

        this.$router.push({
          name: 'ARLink',
          query: { card_id }
        })
      } catch (e) {
        this.isProcessing = false
        const status = e?.response?.status
        if (status === 422) {
          this.$swal.fire('', 'Does not conform to the format.', 'error')
        } else if (status === 502) {
          this.$swal.fire('', '502 Bad Gateway.', 'error')
        } else {
          this.$swal.fire('', `${e.message}`, 'error')
        }
      }
    },
    delay(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve('test123'), ms)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  width: 85%;
  padding: 1rem;
  font-size: 2rem;
}

footer {
  padding: 7% 0;
  text-align: center;
  font-size: 0.9rem;
}
</style>
