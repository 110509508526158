<template>
  <section id="wishHead" class="mb-12">
    <TitleBar>
      <template v-slot:title>Your Wishes Here</template>
      <template v-slot:info>{{ textLength }}</template>
    </TitleBar>
    <div class="relative">
      <img
        :src="`${IMG_ROOT}/index/webAR_inputBG0${pickedId}.jpg`"
        class="w-full"
      />
      <form method="dialog">
        <div class="textarea-placeholder">
          <textarea
            id="textarea"
            class="textarea"
            name="wishes"
            rows="7"
            wrap="hard"
            v-model="content"
            @keydown="onKeyDown"
            @blur="onBlur"
          ></textarea>
          <div class="placeholder" ref="phText">
            <div class="line" v-for="text in demoTexts" :key="text">
              {{ text }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { ref, watch, inject } from 'vue'
import TitleBar from '@/components/TitleBar.vue'

export default {
  name: 'WishInput',
  components: {
    TitleBar
  },
  props: ['pickedId'],
  setup() {
    // Data
    const { demoTexts } = inject('pageData')
    const content = ref('')
    const formContent = ref('')
    const rows = ref(0)
    const textLength = ref(0)
    const phText = ref() // HTML DOM
    const noWatch = ref(false) // 手動開關 content watcher

    const MAX_ROWS = 7

    watch(content, newValue => {
      // 由程式更新 content 的話, form 會拿到 oldValue, 造成預期外結果
      // 故使其由程式更新 value 時, 不執行 watcher
      if (noWatch.value) return (noWatch.value = false)

      // 控制 dummy placeholder 顯示
      if (newValue.length) {
        phText.value.classList.add('invisible')
      } else {
        phText.value.classList.remove('invisible')
      }

      // 使用 formData 取得 textarea wrapped 內容
      const formData = new FormData(document.querySelector('form'))
      let wrappedText = formData.get('wishes')

      // 統一換行符為 \n
      wrappedText = wrappedText.replace(/\r/g, '')

      // onChange trim 後, 使 formData 同步去尾部 \n
      const isTrimmed = /[^\n+]$/.test(newValue)
      if (isTrimmed) {
        wrappedText = wrappedText.trim()
      }

      // 更新 data
      textLength.value = Math.floor(getBytes(wrappedText) / 2)
      rows.value = wrappedText.split('\n').length
      formContent.value = wrappedText

      const contents = wrappedText.split('\n')

      // 限制行數
      if (rows.value > MAX_ROWS) {
        limitRows(contents)
      }
    })

    // 到達限制行數時, 封鎖 Enter 斷行
    function onKeyDown(e) {
      if (rows.value >= MAX_ROWS && e.key === 'Enter') {
        e.preventDefault()
      }
    }

    // Helper function
    function limitRows(contents) {
      // 去掉超出的 Rows
      const cutValue = contents.slice(0, MAX_ROWS).join('\n').trimEnd()

      content.value = cutValue
      formContent.value = cutValue
      textLength.value = Math.floor(getBytes(cutValue) / 2)

      noWatch.value = true
    }

    function getBytes(string = '') {
      const arr = string.match(/[^\x00-\xff]/gi)
      return !arr ? string.length : string.length + arr.length
    }

    return {
      demoTexts,
      content,
      formContent,
      onKeyDown,
      textLength,
      MAX_ROWS,
      phText,
      noWatch
    }
  },
  methods: {
    onBlur() {
      this.content = this.content.trim()
      this.$emit('on-change', this.formContent.trim())
    }
  }
}
</script>

<style lang="scss" scoped>
$textarea-line-height: 2.2rem;

@mixin absolute-x-center {
  @apply absolute left-1/2 transform -translate-x-1/2;
}

@mixin textarea-layout {
  @apply w-full px-4 border border-white border-solid rounded-sm;
  font-size: 1.6rem;
}

.textarea-placeholder {
  width: 82%;
  bottom: 7%;
  @include absolute-x-center();

  .textarea {
    @include textarea-layout;
    line-height: $textarea-line-height;
    resize: none;
    overflow: hidden;
    appearance: none; // 關掉 iOS 預設樣式
    background: transparent;
    color: white;

    &:focus {
      box-shadow: 0 0 10px 3px #94e8c8;
      outline: 0;
    }
  }

  .placeholder {
    @include textarea-layout;
    color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    .line {
      line-height: $textarea-line-height;
    }
  }
}
</style>
