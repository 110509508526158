import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  width: '25rem',
  padding: '2rem 1.25rem',
  confirmButtonColor: '#e29b38'
})

export default {
  install(app) {
    app.config.globalProperties.$swal = Toast
    app.provide('swal', Toast)
  }
}
