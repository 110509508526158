<template>
  <footer>
    <div>Copyright © 2020 Speed 3D Inc. ALL RIGHTS RESERVED.</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
