<template>
  <section class="mb-12">
    <TitleBar>
      <template v-slot:title>Christmas Cards</template>
      <template v-slot:info> {{ cards.length }} templates </template>
    </TitleBar>
    <div class="flex flex-wrap text-center">
      <div
        class="t-col w-1/2 relative odd:pr-1 even:pl-1"
        v-for="card in cards"
        :key="`card${card.id}`"
      >
        <input
          type="radio"
          name="t-cards"
          class="absolute top-1/2 left-1/2 right-1/4"
          :id="card.id"
          :value="card.id"
          v-model="pickedCardId"
          @change="onChange"
        />
        <label
          :for="card.id"
          class="inline-block border-2"
          :style="`border-image-source: url(${IMG_ROOT}/index/border.png)`"
        >
          <img :src="`${IMG_ROOT}/index/${card.img}`" class="w-full" />
        </label>
        <a
          :href="`https://${TRY_IT_PATH}/?try_id=${card.id}`"
          target="_blank"
          rel="noreferrer noopener"
          class="btn-green btn-try"
          >Try it</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import { ref, inject } from 'vue'
import TitleBar from '@/components/TitleBar.vue'

export default {
  components: {
    TitleBar
  },
  setup(_, context) {
    // Data
    const { cards } = inject('pageData')
    const pickedCardId = ref(1)
    const head = ref()

    // Event
    function onChange() {
      context.emit('pick-card', pickedCardId.value)
    }

    return {
      cards,
      pickedCardId,
      head,
      onChange
    }
  }
}
</script>

<style lang="scss" scoped>
$card-btn-width: 60%;

label {
  border-radius: 0.6rem;
  padding: 0.4rem;
  border-image-width: 0.8rem;
  border-image-slice: 0;
  border-image-repeat: round;
  border-style: inset;

  img {
    border-radius: 0.6rem;
    filter: brightness(0.6);
    cursor: pointer;
  }
}

.btn-try {
  position: absolute;
  bottom: 7%;
  left: (100 - $card-btn-width) / 2;
  width: $card-btn-width;
  font-size: 2.2rem;
}

input:checked + label {
  border-image-slice: 10 !important;

  img {
    filter: brightness(1) !important;
  }
}
</style>
