import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import swalPlugin from './utils/swal.js'
import CardAPI from './apis/cardAPI.js'
import './index.scss'

// 初始化時，自動匹配 device-width
const width = document.documentElement.clientWidth
if (width < 576) {
  const scale = +(width / 576).toFixed(2)
  document
    .querySelector('meta[name=viewport]')
    .setAttribute('content', `width=device-width, initial-scale=${scale}`)
}

// Global Setting
const HOST_NAME = 'ar-test.spe3d.co'
const AR_VIEW_PATH = `${HOST_NAME}/ar-view-landing-page`
const TRY_IT_PATH = `${HOST_NAME}/ar_view`

CardAPI.setBaseURL(HOST_NAME)

// init Vue app
const app = createApp(App)

app.config.globalProperties.IMG_ROOT = './img'
app.config.globalProperties.AR_VIEW_PATH = AR_VIEW_PATH
app.config.globalProperties.TRY_IT_PATH = TRY_IT_PATH

app.use(router)
app.use(swalPlugin)
app.use(VueScrollTo)
app.provide('scrollTo', VueScrollTo.scrollTo)

app.mount('#app')
